import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { Button } from "@/UI/Button";
import { ControlledCheckbox } from "@/UI/CheckBox";
import { ControlledMultiSelect } from "@/UI/MultiSelect";
import { FC, useEffect, useState } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { ControlledInput } from "@/UI/Input";
import { E164_PHONE_REGEX } from "@/utils/helpers/PhoneNumberRegex";
import { toast } from "react-toastify";
import { useAuthContext } from "../Auth/AuthWrapper";
import { useCreateUser } from "@/lib/react-query/mutationHooks/useCreateUser";
import { useEditUser } from "@/lib/react-query/mutationHooks/useEditUser";
import { useNavigate } from "react-router-dom";
import { User } from "@/types/users/general";
import { useResendInvite } from "@/lib/react-query/mutationHooks/useResendInvite";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  userDetailsFormDefaultValues,
  userFormToCreateQueryMapper,
  userFormToEditQueryMapper,
} from "@/utils/formDefinitions/users/general";
import * as amplitude from "@amplitude/analytics-browser";
import { ControlledSelect } from "@/UI/Select/ControlledSelect";
import { refinedOptionSchema } from "@/types/option";
import { useDeleteUser } from "@/lib/react-query/mutationHooks/useDeleteUser";
import { DangerModal } from "@/UI/Modal";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useAccounts } from "@/lib/react-query/queryHooks/useAccounts";
import { useDivisionsForAccount } from "@/lib/react-query/queryHooks/useDivisionsForAccount";
import { LoadingSpinner } from "@/UI/Loading";

const userDetailsFormSchema = z
  .object({
    account: z.object({ id: z.string(), value: z.string() }),
    division: z
      .object({ value: z.string(), label: z.string() })
      .array()
      .optional(),
    branch: z
      .object({ value: z.string(), label: z.string() })
      .array()
      .min(1, { message: "Please provide a Branch" }),
    email: z.string().optional(),
    firstName: z.string().min(1, { message: "Please provide a First Name." }),
    lastName: z.string().min(1, { message: "Please provide a Last Name." }),
    permissions: z
      .object({ value: z.string(), label: z.string() })
      .array()
      .min(1, { message: "Please provide a Permission" }),
    phoneNumber: z.string().optional(),
    authMethod: refinedOptionSchema,
    occupations: z
      .object({ value: z.string(), label: z.string() })
      .array()
      .optional(),
    veteran: z.boolean(),
  })
  .superRefine(({ authMethod, email, phoneNumber }, context) => {
    if (
      authMethod.value === "web" &&
      !z.string().email().safeParse(email).success
    ) {
      return context.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Valid email is required for web authentication",
        path: ["email"],
      });
    }
    if (
      authMethod.value === "mobile" &&
      !E164_PHONE_REGEX.test(phoneNumber ?? "")
    ) {
      return context.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "Valid phone number is required for mobile authentication. (Eg. +1##########)",
        path: ["phoneNumber"],
      });
    }

    if (authMethod.value === "both") {
      if (!z.string().email().safeParse(email).success) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Valid email is required for web authentication",
          path: ["email"],
        });
      }
      if (!E164_PHONE_REGEX.test(phoneNumber ?? "")) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            "Valid phone number is required for mobile authentication. (Eg. +1##########)",
          path: ["phoneNumber"],
        });
      }
      return context;
    }
  });

export type UserDetailsFormSchema = z.infer<typeof userDetailsFormSchema>;

type UserFormProps = {
  edit: boolean;
  userData?: User;
};

// This will come via API in the future
const scopes = [
  { value: "general:tracker", label: "general:tracker" },
  { value: "mobile:provisioning", label: "mobile:provisioning" },
  { value: "mobile:user", label: "mobile:user" },
  { value: "mobile:vtrackers", label: "mobile:vtrackers" },
  { value: "timecard:admin", label: "timecard:admin" },
  { value: "timecard:user", label: "timecard:user" },
  { value: "web:assetmap", label: "web:assetmap" },
  { value: "web:schedule", label: "web:schedule" },
  { value: "web:workflowcrud", label: "web:workflowcrud" },
  { value: "web:assetcrud", label: "web:assetcrud" },
  { value: "web:groupcrud", label: "web:groupcrud" },
  { value: "web:usercrud", label: "web:usercrud" },
  { value: "web:quoting", label: "web:quoting" },
  { value: "mobilehome:user", label: "mobilehome:user" },
  { value: "mobileassets:user", label: "mobileassets:user" },
];

const authMethodOptions = [
  { id: "1", value: "web" },
  { id: "2", value: "mobile" },
  { id: "3", value: "both" },
];

const UserForm: FC<UserFormProps> = ({ userData, edit }) => {
  const { accountId, hasRibbiotAdmin = false } = useAuthContext();

  const [selectedAccountId, setSelectedAccountId] = useState<string>(accountId);
  const [occupations, setOccupations] = useState<
    { value: string; label: string }[]
  >([]);
  const [branches, setBranches] = useState<{ value: string; label: string }[]>(
    []
  );
  const [deleteUserModal, setDeleteUserModal] = useState({ open: false });
  const [editLoginInformationModal, setEditLoginInformationModal] = useState({
    open: false,
  });
  const [confirmEditLoginInformation, setConfirmEditLoginInformation] =
    useState(false);

  const {
    data: accountQueryData,
    isLoading: isAccountLoading,
    isSuccess,
  } = useAccount({
    accountId,
    options: {
      enabled: !hasRibbiotAdmin,
    },
  });

  const { data: accountsQueryData, isLoading: isAccountsLoading } = useAccounts(
    {
      options: {
        enabled: hasRibbiotAdmin,
      },
    }
  );

  const { data: divisionsQueryData, isLoading: isDivisionsLoading } =
    useDivisions({
      options: {
        enabled: !hasRibbiotAdmin,
      },
    });

  const {
    data: divisionsForAnyAccountQueryData,
    isLoading: isDivisionsForAccountLoading,
  } = useDivisionsForAccount({
    enabled: hasRibbiotAdmin && !!selectedAccountId,
    accountId: selectedAccountId,
  });

  const navigate = useNavigate();

  const { mutate: editUser, isPending: editLoading } = useEditUser();

  const { mutate: resendInvite, isPending: resendInviteLoading } =
    useResendInvite();

  const { mutate: createUser, isPending: createLoading } = useCreateUser();

  const { mutate: deleteUser, isPending: deleteLoading } = useDeleteUser();

  const accounts = hasRibbiotAdmin
    ? accountsQueryData?.data ?? []
    : [accountQueryData?.data];

  const accountOptions = accounts.map((account) => ({
    id: account?.id ?? "",
    value: account?.account_name ?? "",
  }));

  const divisionsOptions = hasRibbiotAdmin
    ? divisionsForAnyAccountQueryData?.data.map((division) => ({
        value: division.id,
        label: division.divisionName,
      })) ?? []
    : divisionsQueryData?.data.map((division) => ({
        value: division.id,
        label: division.divisionName,
      })) ?? [];

  const handleOccupations = (accountId: string) => {
    const completeSelectedAccountData = accounts?.find(
      (account) => account?.id === accountId
    );
    const occupationOptions = completeSelectedAccountData?.occupations?.map(
      (occupation) => ({
        value: occupation.id,
        label: occupation.name,
      })
    );
    return occupationOptions || [];
  };

  const handleBranches = (accountId: string) => {
    const completeSelectedAccountData = accounts?.find(
      (account) => account?.id === accountId
    );
    const branchsOptions = completeSelectedAccountData?.branches?.map(
      (branch) => ({
        value: branch.id,
        label: branch.name,
      })
    );
    return branchsOptions || [];
  };

  const onSubmit = (data: UserDetailsFormSchema) => {
    if (edit) {
      const props = userFormToEditQueryMapper(data, userData?.accountId);
      if (userData) {
        editUser(
          {
            props,
            userId: userData?.id,
          },
          {
            onSuccess: () => {
              toast.success("User Edited");
              navigate("/users");
            },
            onError: (error) => {
              toast.error(error.message);
            },
          }
        );
      }
    } else {
      const props = userFormToCreateQueryMapper(data);
      createUser(
        { props },
        {
          onSuccess: () => {
            toast.success("User Created");
            amplitude.track(AMPLITUDE_EVENTS.USER_CREATED, {
              email: data.email,
              occupations: data.occupations,
              permissions: data.permissions?.map(
                (permission) => permission.value
              ),
            });
            navigate("/users");
          },
          onError: (error) => {
            toast.error(error.message);
          },
        }
      );
    }
  };

  const onError = (data: FieldErrors<UserDetailsFormSchema>) =>
    console.error(data);

  const onResendInvite = () => {
    if (!userData?.id || !userData?.accountId) {
      toast.error("Error re-sending invite.");
      return;
    }
    resendInvite(
      { id: userData.id },
      {
        onError: (error) => {
          toast.error(error.message);
        },
        onSuccess: () => {
          toast.success("Invite sent successfully.");
        },
      }
    );
  };

  const onDelete = () => {
    if (userData) {
      setDeleteUserModal({ open: false });
      deleteUser(
        { userId: userData.id },
        {
          onSuccess: () => {
            toast.success("User Deleted");
            navigate("/users", { replace: true });
          },
          onError: () => {
            toast.error("Error deleting user");
            navigate("/users", { replace: true });
          },
        }
      );
    }
  };

  const selectedAccount = accounts?.find(
    (account) => account?.id === userData?.accountId
  );

  const handleFormValues = () => {
    if (userData) {
      const permissions = userData.permissions?.map((permission) => ({
        label: permission,
        value: permission,
      }));

      const branchesOptions = handleBranches(selectedAccount?.id ?? "");
      const selectedBranches = userData.branchIds.flatMap((id) =>
        branchesOptions?.filter((branch) => branch && branch.value === id)
      );
      const selectedDivisions = userData?.divisionIds?.flatMap((id) =>
        divisionsOptions?.filter(
          (division) => division && division.value === id
        )
      );

      const occupationOptions = handleOccupations(selectedAccount?.id || "");
      const occupationsSet = new Set(userData.occupations);
      const occupations = occupationOptions.filter((occupation) =>
        occupationsSet.has(occupation.value)
      );

      return {
        account: selectedAccount
          ? { id: selectedAccount.id, value: selectedAccount.account_name }
          : { id: accountId, value: accountId },
        branch: selectedBranches,
        division: selectedDivisions,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        permissions,
        occupations,
        veteran: userData.veteran,
        phoneNumber: userData.phoneNumber,
        authMethod: authMethodOptions.find(
          (option) => option.value === userData?.authMethod
        ) ?? { id: "1", value: "web" },
      };
    }
    return {
      ...userDetailsFormDefaultValues,
      authMethod: { id: "1", value: "web" },
      account: accounts
        ? {
            id: accounts[0]?.id || "",
            value: accounts[0]?.account_name || "",
          }
        : { id: "", value: "" },
    };
  };

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    resetField,
    watch,
    setValue,
  } = useForm<UserDetailsFormSchema>({
    defaultValues: userDetailsFormDefaultValues,
    values: handleFormValues() as UserDetailsFormSchema,
    resolver: zodResolver(userDetailsFormSchema),
  });

  const watchedAccountValue = watch("account");

  useEffect(() => {
    const selectedFormAccountId = watchedAccountValue?.id || "";
    setSelectedAccountId(selectedFormAccountId);
    const occupationOptions = handleOccupations(selectedFormAccountId);
    setOccupations(occupationOptions || []);
    const branchesOptions = handleBranches(selectedFormAccountId);
    setBranches(branchesOptions || []);
    setOccupations(occupationOptions || []);
    resetField("branch");
    resetField("occupations");
    if (selectedFormAccountId !== selectedAccountId) {
      setValue("division", []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedAccountValue]);

  useEffect(() => {
    const occupationOptions = handleOccupations(selectedAccount?.id || "");
    const branchesOptions = handleBranches(selectedAccount?.id ?? "");

    setOccupations(occupationOptions);
    setBranches(branchesOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (isSuccess) {
      setValue("account", {
        id: accountQueryData.data.id,
        value: accountQueryData.data.account_name,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const isLoading =
    isAccountLoading ||
    isAccountsLoading ||
    isDivisionsForAccountLoading ||
    isDivisionsLoading;

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="grid grid-cols-1 gap-4 min-[877px]:grid-cols-3">
          <ControlledInput
            control={control}
            name="firstName"
            label="First Name"
          />
          <ControlledInput
            control={control}
            name="lastName"
            label="Last Name"
          />
          <ControlledInput
            control={control}
            name="email"
            label="Email"
            onClick={() => {
              if (confirmEditLoginInformation || !edit) return;
              setEditLoginInformationModal({ open: true });
            }}
          />
          <ControlledSelect<UserDetailsFormSchema>
            control={control}
            label="Account"
            name="account"
            options={accountOptions}
            disabled={edit || (!edit && !hasRibbiotAdmin)}
            hidePlaceholder
          />
          <ControlledSelect<UserDetailsFormSchema>
            control={control}
            label="Authentication Method"
            name="authMethod"
            options={authMethodOptions}
            onClick={() => {
              if (confirmEditLoginInformation || !edit) return;
              setEditLoginInformationModal({ open: true });
            }}
            hidePlaceholder
          />
          <ControlledInput
            control={control}
            name="phoneNumber"
            label="Phone Number"
            onClick={() => {
              if (confirmEditLoginInformation || !edit) return;
              setEditLoginInformationModal({ open: true });
            }}
            onChange={(e) => {
              const number = e.target.value;
              if (number === "+1") {
                setValue("phoneNumber", "", { shouldDirty: true });
                return;
              }
              if (number.startsWith("+1") || number === "") {
                setValue("phoneNumber", number, { shouldDirty: true });
                return;
              }
              setValue("phoneNumber", "+1" + number, { shouldDirty: true });
            }}
          />
          <div className="grid grid-cols-2 justify-center">
            <ControlledCheckbox
              control={control}
              name="veteran"
              label="Veteran"
              error={errors.veteran}
            />
          </div>
          <ControlledMultiSelect<UserDetailsFormSchema>
            control={control}
            name="occupations"
            label="Occupation"
            options={occupations}
          />
          <ControlledMultiSelect<UserDetailsFormSchema>
            control={control}
            name="division"
            label="Divisions"
            options={divisionsOptions}
          />
          <ControlledMultiSelect<UserDetailsFormSchema>
            control={control}
            name="branch"
            label="Branches"
            options={branches}
          />
          <ControlledMultiSelect<UserDetailsFormSchema>
            control={control}
            name="permissions"
            label="Permissions"
            options={
              hasRibbiotAdmin
                ? [
                    { value: "ribbiot:admin", label: "ribbiot:admin" },
                    ...scopes,
                  ]
                : scopes
            }
          />
        </div>

        <div className="mt-6 flex flex-col justify-between gap-5 min-[877px]:flex-row">
          {edit && (
            <div className="flex flex-col items-center gap-5 min-[877px]:flex-row">
              <Button
                label="Re-send Invite"
                variant="secondary"
                type="button"
                onClick={onResendInvite}
                loading={resendInviteLoading}
                className="rounded-md"
              />
              <Button
                label="Delete User"
                variant="tertiary"
                type="button"
                onClick={() => setDeleteUserModal({ open: true })}
                loading={deleteLoading}
                className="rounded-md"
              />
            </div>
          )}

          <div className="flex flex-col items-center gap-5 min-[877px]:flex-row">
            <Button
              label="Cancel"
              variant="secondary"
              type="button"
              onClick={() => navigate("/users")}
              disabled={editLoading || createLoading || deleteLoading}
              className="rounded-md"
            />
            <Button
              label={edit ? "Update" : "Create Crew Member"}
              variant="primary"
              disabled={!isDirty}
              loading={editLoading || createLoading || deleteLoading}
              className="rounded-md"
            />
          </div>
        </div>
      </form>

      <DangerModal
        cancelAction={() =>
          setDeleteUserModal({
            open: false,
          })
        }
        cancelButtonDisabled={deleteLoading}
        confirmAction={onDelete}
        confirmButtonDisabled={deleteLoading}
        message="Are you sure you want to delete this user? This action cannot be undone."
        open={deleteUserModal.open}
        title="Delete User"
      />

      <DangerModal
        cancelAction={() =>
          setEditLoginInformationModal({
            open: false,
          })
        }
        cancelButtonDisabled={false}
        confirmAction={() => {
          setConfirmEditLoginInformation(true);
          setEditLoginInformationModal({
            open: false,
          });
        }}
        confirmButtonDisabled={false}
        message="Editing this information is a destructive action and may cause
        this user to have to re-create their account. Are you sure you
        want to proceed?"
        open={editLoginInformationModal.open}
        title="Edit Login Information"
      />
    </>
  );
};

export { UserForm };
