import { classNames } from "../../utils/helpers/classNames";
import { useAuthContext } from "../Auth/AuthWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import { UserMenu } from "../UserMenu";
import {
  Nav,
  navBase,
  settingsNavigation,
  timecardAdminNavigation,
  webAssetCrudNavigation,
  webAssetMapNavigation,
  webQuotingNavigation,
  webScheduleNavigation,
  webUserCrudNavigation,
} from "../../utils/navigation";
import { AssetsExpandedContent, JobsExpandedContent } from "./ExpandedContent";
import { handleNavigation } from "@/utils/helpers/handleNavigation";
import { UsersExpandedContent } from "./ExpandedContent/UsersExpandedContent";
import { SettingsExpandedContent } from "./ExpandedContent/SettingsExpandedContent";
import { PATHNAME_REGEX } from "@/utils/helpers/pathnameRegex";

const Sidebar = () => {
  const {
    hasTimecardAdmin,
    hasRibbiotAdmin,
    hasAssetCrud,
    hasUserCrud,
    hasWebAssetMap,
    hasWebSchedule,
    hasWebQuoting,
  } = useAuthContext();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const adminNav = [
    ...navBase,
    ...webAssetMapNavigation,
    ...webScheduleNavigation,
    ...timecardAdminNavigation,
    ...webQuotingNavigation,
    ...webAssetCrudNavigation,
    ...webUserCrudNavigation,
  ];

  const nav: Nav[] = hasRibbiotAdmin
    ? adminNav
    : [
        ...navBase,
        ...(hasWebSchedule ? [...webScheduleNavigation] : []),
        ...(hasWebAssetMap ? [...webAssetMapNavigation] : []),
        ...(hasTimecardAdmin ? [...timecardAdminNavigation] : []),
        ...(hasAssetCrud ? [...webAssetCrudNavigation] : []),
        ...(hasUserCrud ? [...webUserCrudNavigation] : []),
        ...(hasWebQuoting ? [...webQuotingNavigation] : []),
      ];

  const bottomNav = hasRibbiotAdmin ? [...settingsNavigation] : [];

  const handleSubrefs = (subrefs: string[]) => {
    const match = subrefs.find((subref) => pathname === subref);
    return Boolean(match);
  };

  const handleExpand = (pathname: string, nav: Nav[]) => {
    const sanitizedPathname = pathname.replace(PATHNAME_REGEX, "");
    const expandedRoutes = nav.flatMap((nav) => nav.expandedRoutes);
    const match = expandedRoutes.find((route) => sanitizedPathname === route);
    return Boolean(match);
  };

  const handleExpandedContent = (pathname: string, nav: Nav[]) => {
    const sanitizedPathname = pathname.replace(PATHNAME_REGEX, "");
    const currNav = nav.find((item) =>
      item.expandedRoutes.includes(sanitizedPathname)
    );

    if (currNav) {
      switch (currNav.name) {
        case "Schedule":
          return <JobsExpandedContent />;
        case "Assets":
          return <AssetsExpandedContent />;
        case "Users":
          return <UsersExpandedContent />;
        case "Settings":
          return <SettingsExpandedContent />;
        default:
          return <></>;
      }
    }
    return <></>;
  };

  return (
    <>
      <nav
        className={classNames(
          "z-10 flex h-full w-[60px] shrink-0 flex-col items-center justify-between bg-palette-quarternaryGrey pb-[34px] pt-6"
        )}
      >
        <ul className="flex w-full flex-col gap-y-8">
          {nav.map((item, idx) => {
            return (
              <li
                key={item.name}
                className={classNames(
                  "flex w-full flex-col items-center",
                  pathname.includes(item.href) && idx !== 0
                    ? "border-l-4 border-theme-green-primary"
                    : "",
                  handleSubrefs(item.subrefs)
                    ? "border-l-4 border-theme-green-primary"
                    : ""
                )}
              >
                <a
                  className={"group flex gap-x-3"}
                  href={item.href}
                  onClick={(event) =>
                    handleNavigation({
                      event,
                      href: item.href,
                      navigate,
                      pathname,
                    })
                  }
                  title={item.name}
                >
                  <item.icon
                    aria-hidden="true"
                    stroke={classNames(
                      pathname.includes(item.href) && idx !== 0
                        ? "black"
                        : "#575757"
                    )}
                    fill={classNames(
                      pathname.includes(item.href) && idx !== 0
                        ? "black"
                        : "#575757"
                    )}
                  />
                </a>
              </li>
            );
          })}
        </ul>

        <ul className="flex w-full flex-col gap-y-[22px]">
          {bottomNav.map((item) => (
            <li
              key={item.name}
              className={classNames(
                "flex w-full flex-col items-center",
                pathname.includes(item.href) &&
                  "border-l-4 border-theme-green-primary"
              )}
            >
              <a
                className={"flex gap-x-3"}
                href={item.href}
                onClick={(event) =>
                  handleNavigation({
                    event,
                    href: item.href,
                    navigate,
                    pathname,
                  })
                }
                title={item.name}
              >
                <item.icon />
              </a>
            </li>
          ))}

          <li className={classNames("flex w-full flex-col items-center")}>
            <UserMenu />
          </li>
        </ul>
      </nav>

      <div
        className={classNames(
          "flex h-full w-[164px] shrink-0 flex-col items-center bg-[#f2f7f9] pt-6",
          handleExpand(pathname, [...nav, ...bottomNav]) ? "flex" : "hidden"
        )}
      >
        {handleExpandedContent(pathname, [...nav, ...bottomNav])}
      </div>
    </>
  );
};

export { Sidebar };
