/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { add, sub } from "date-fns";
import { alphaNumericSort } from "@/utils/helpers/alphaNumericSort";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { Branch, Division, User } from "@/types/users/general";
import { Button } from "@/UI/Button";
import { classNames } from "@/utils/helpers/classNames";
import { ControlledDatePicker } from "@/UI/DatePicker";
import { ControlledInput } from "@/UI/Input";
import { ControlledSelect } from "@/UI/Select/ControlledSelect";
import { ControlledTextArea } from "@/UI/TextArea";
import { CustomAutocomplete } from "./CustomAutocomplete";
import { CustomLocation } from "@/components/JobManagement/JobForm/CustomLocation";
import { FC, useEffect, useState } from "react";
import { DangerModal } from "@/UI/Modal";
import { Job } from "@/types/jobManagementService/general";
import { Loading } from "@/UI/Loading";
import { Location } from "@/types/jobManagementService/general";
import { toast } from "react-toastify";
import { useCreateJob } from "@/lib/react-query/mutationHooks/useCreateJob";
import { useDeleteJob } from "@/lib/react-query/mutationHooks/useDeleteJob";
import { useEditJob } from "@/lib/react-query/mutationHooks/useEditJob";
import { useEditJobDates } from "@/lib/react-query/mutationHooks/useEditJobDates";
import { useForm } from "react-hook-form";
import { useGoogleMapsAPIKey } from "@/lib/react-query/queryHooks/useGoogleMapsAPIKey";
import { useLocation, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateJobFormSchema,
  DEFAULT_VALUES,
  JobFormSchema,
  STATUS_OPTIONS,
  jobFormToMutateMapper,
  jobFormToQueryMapper,
} from "@/utils/formDefinitions/jobManagementService/general";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  branches?: Branch[];
  divisions?: Division[];
  edit: boolean;
  job?: Job;
  users?: User[];
};

const JobForm: FC<Props> = ({ branches, divisions, edit, job, users }) => {
  const [openMap, setOpenMap] = useState(false);
  const [formLocation, setFormLocation] = useState<
    Location | string | undefined
  >("");
  const [endDateMaxDate, setEndDateMaxDate] = useState<Date>();
  const [startDateMinDate, setStartDateMinDate] = useState<Date>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const navigate = useNavigate();
  const { data: apiKey } = useGoogleMapsAPIKey();

  const BRANCH_OPTIONS = (
    branches?.map((branch) => {
      return {
        id: branch.id,
        value: branch.name,
      };
    }) || []
  ).sort((a, b) => alphaNumericSort(a.value, b.value));

  const DIVISION_OPTIONS = (
    divisions?.map((division) => {
      return {
        id: division.id || "",
        value: division.divisionName || "",
      };
    }) || []
  ).sort((a, b) => alphaNumericSort(a.value, b.value));

  const USER_OPTIONS = (
    users?.map((user) => {
      return {
        id: user.id,
        value: `${user.firstName} ${user.lastName}`,
      };
    }) || []
  ).sort((a, b) => alphaNumericSort(a.value, b.value));

  const parseUser = (id?: string | null) => {
    if (!id) return { id: "", value: "" };

    const user = USER_OPTIONS?.find((user) => user.id === id);
    return user ? user : { id: "", value: "" };
  };

  const parseBranch = (id: string | null | undefined) => {
    if (!id) return { id: "", value: "" };

    const branch = BRANCH_OPTIONS.find((branch) => branch.id === id);
    return branch ? branch : { id: "", value: "" };
  };

  const parseDivision = (id: string | null | undefined) => {
    if (!id) return { id: "", value: "" };

    const division = DIVISION_OPTIONS.find((division) => division.id === id);
    return division ? division : { id: "", value: "" };
  };

  const parseDate = (date: Date | string | null | undefined) => {
    if (!date) {
      const dateString = new Date().toLocaleDateString(); // '4/23/2024'
      // the dateString needs to be parsed into a value with no timestamp in order to create a mid night one
      return new Date(`${dateString} 00:00:00`).toString();
    }

    // the API date format here is 2024-04-09
    return new Date(`${date} 00:00:00`).toString();
  };

  const { control, handleSubmit, setValue, reset, getValues, formState } =
    useForm<JobFormSchema>({
      defaultValues: DEFAULT_VALUES,
      values: {
        location:
          job && job.address && job.address.lat
            ? {
                lat: job.address?.lat,
                lng: job.address?.lng,
                name: job.address?.name || job.address.formattedAddress || "",
              }
            : undefined,
        name: job?.name || "",
        number: job?.number || "",
        startDate: parseDate(job?.startDate),
        status: { id: job?.status || "", value: job?.status || "" },
        branchId: parseBranch(job?.branchId),
        customer: job?.customer?.name || "",
        customerContact: job?.customer?.contactName || "",
        customerEmail: job?.customer?.email || "",
        customerPhone: job?.customer?.phone || "",
        divisionId: parseDivision(job?.divisionId),
        endDate: job?.endDate ? parseDate(job.endDate) : "",
        notes: job?.notes || "",
        projectOwner: parseUser(job?.projectOwner?.[0]),
        salesPerson: parseUser(job?.salesPerson?.[0]),
      },
      resetOptions: {
        keepValues: edit ? false : true,
      },
      resolver: zodResolver(CreateJobFormSchema),
    });

  const { mutateAsync: mutateJobAsync, isPending: mutateJobLoading } =
    useEditJob();
  const { mutateAsync: createJobAsync, isPending: createJobLoading } =
    useCreateJob();
  const { mutateAsync: updateJobDatesAsync, isPending: updateJobDatesLoading } =
    useEditJobDates();
  const { mutateAsync: deleteJobAsync, isPending: deleteJobLoading } =
    useDeleteJob();

  const onSubmit = async (formData: JobFormSchema) => {
    if (edit && job) {
      try {
        const editData = jobFormToMutateMapper(formData, job.id);
        await mutateJobAsync({ props: editData });

        const updatedJobDates = {
          id: job.id,
          endDate: new Date(formData.endDate),
          startDate: new Date(formData.startDate),
        };
        await updateJobDatesAsync({ props: updatedJobDates });

        const editedFields = Object.keys(formState.dirtyFields);
        amplitude.track(AMPLITUDE_EVENTS.JOB_DETAILS_UPDATED, {
          edited_fields: editedFields,
        });

        toast.success("Job edited.");
      } catch (error) {
        toast.error("Error editing Job.");
      }
    } else {
      try {
        const createData = jobFormToQueryMapper(formData);
        const {
          data: { id },
        } = await createJobAsync({ props: createData });
        toast.success("Job Created.");
        navigate(`/jobs/job-detail?jobId=${id}`);
      } catch (error) {
        toast.error("Error creating Job.");
      }
    }
  };

  const onDelete = async () => {
    if (job && edit) {
      try {
        await deleteJobAsync({ jobId: job.id });
        toast.success("Job deleted.");
        navigate("/schedule");
      } catch (error) {
        toast.error("Error Deleting Job.");
      }
    }
  };

  const onClickEndAdorment = () => {
    const currLocation = getValues("location");
    setFormLocation(currLocation);
    setOpenMap(true);
  };

  const onStartDateChange = (date: Date | string | null) => {
    if (date) {
      setEndDateMaxDate(add(new Date(date), { days: 100 }));
    } else {
      setEndDateMaxDate(undefined);
    }
  };

  const onEndDateChange = (date: Date | string | null) => {
    if (date) {
      setStartDateMinDate(sub(new Date(date), { days: 100 }));
    } else {
      setStartDateMinDate(undefined);
    }
  };

  const locationState = useLocation().state;
  useEffect(() => {
    if (!locationState) return;
    reset({
      ...locationState.jobMapping,
      customerPhone: locationState.jobMapping?.customerPhone?.replace(
        /\s+/g,
        ""
      ),
      divisionId: DIVISION_OPTIONS.find(
        (division) => division.id === locationState.jobMapping.divisionId
      ),
      branchId: BRANCH_OPTIONS.find(
        (branch) => branch.id === locationState.jobMapping.branchId
      ),
      salesPerson: USER_OPTIONS.find(
        (user) => user.id === locationState.jobMapping.userId
      ),
    });
  }, []);

  if (!apiKey) {
    return <Loading />;
  }

  return (
    <>
      <form
        className="grid grid-cols-2 gap-4 px-1"
        onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") ev.preventDefault();
        }}
      >
        <div className="grid grid-cols-2 grid-rows-5 gap-4">
          <ControlledInput
            containerClasses="col-span-2"
            control={control}
            label="Job Name*"
            name="name"
          />

          <CustomAutocomplete
            setValue={setValue}
            onClickEndAdorment={onClickEndAdorment}
            initialLocation={job?.address}
          />

          <ControlledSelect
            control={control}
            label="Salesperson"
            name="salesPerson"
            options={USER_OPTIONS}
          />

          <ControlledSelect
            control={control}
            label="Project Owner"
            name="projectOwner"
            options={USER_OPTIONS}
          />

          <ControlledInput control={control} name="customer" label="Customer" />

          <ControlledInput
            control={control}
            label="Customer Contact"
            name="customerContact"
          />

          <ControlledInput
            control={control}
            label="Customer Email"
            name="customerEmail"
          />

          <ControlledInput
            control={control}
            label="Customer Phone"
            name="customerPhone"
          />
        </div>

        <div className="grid grid-cols-2 grid-rows-5 gap-4">
          <ControlledInput control={control} name="number" label="Job Number" />

          <ControlledSelect
            control={control}
            name="status"
            options={STATUS_OPTIONS}
            label="Job Status"
          />

          <ControlledDatePicker
            control={control}
            minDate={startDateMinDate}
            externalChangeHandler={onStartDateChange}
            name="startDate"
            label="Start Date*"
            parseValue
          />

          <ControlledDatePicker
            control={control}
            maxDate={endDateMaxDate}
            externalChangeHandler={onEndDateChange}
            name="endDate"
            label="End Date*"
            parseValue
          />

          <ControlledSelect
            control={control}
            name="divisionId"
            options={DIVISION_OPTIONS}
            label="Division*"
          />

          <ControlledSelect
            control={control}
            name="branchId"
            options={BRANCH_OPTIONS}
            label="Branch"
          />

          <ControlledTextArea
            control={control}
            name="notes"
            containerClassNames="col-span-2 row-span-2"
            textareaStyles="!resize-none !h-full"
            label="Notes"
          />
        </div>

        <div
          className={classNames(
            "flex",
            edit ? "col-span-2 justify-between" : "col-start-2 justify-end"
          )}
        >
          <Button
            className={classNames("rounded-md", edit ? "block" : "hidden")}
            disabled={
              mutateJobLoading ||
              createJobLoading ||
              updateJobDatesLoading ||
              deleteJobLoading
            }
            label="Delete Job"
            type="button"
            variant="secondary"
            onClick={() => setDeleteModalOpen(true)}
          />

          <div className="flex gap-x-3">
            <Button
              className="rounded-md"
              disabled={
                mutateJobLoading ||
                createJobLoading ||
                updateJobDatesLoading ||
                deleteJobLoading
              }
              label="Cancel"
              type="button"
              variant="secondary"
              onClick={() => {
                if (edit) {
                  navigate(`/jobs/dashboard?jobId=${job?.id}`);
                } else {
                  navigate("/schedule");
                }
              }}
            />

            <Button
              disabled={
                mutateJobLoading ||
                createJobLoading ||
                updateJobDatesLoading ||
                deleteJobLoading
              }
              label={edit ? "Edit Job" : "Create Job"}
              variant="primary"
              className="rounded-md"
              type="submit"
            />
          </div>
        </div>
      </form>

      {openMap && (
        <CustomLocation
          open={openMap}
          jobFormInputLocation={formLocation}
          setOpen={setOpenMap}
          setValue={setValue}
        />
      )}

      <DangerModal
        cancelAction={() => setDeleteModalOpen(false)}
        cancelButtonDisabled={deleteJobLoading}
        confirmAction={onDelete}
        confirmButtonDisabled={deleteJobLoading}
        message="Are you sure you want to delete this Job? This action cannot be undone."
        open={deleteModalOpen}
        title="Delete Job"
      />
    </>
  );
};

export { JobForm };
