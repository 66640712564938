/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { format, isDate } from "date-fns";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { z } from "zod";
import { useUsers } from "@/lib/react-query/queryHooks/useUsers";
import { Option } from "@/types/option";
import { getDisplayValue } from "../helpers/forms/formRendererHelpers";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { usePricebookItems } from "@/lib/react-query/queryHooks/usePricebookItems";

export const useDocumentDetailHelpers = () => {
  const { data: accountData } = useAccount({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: branches } = useBranches({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: users } = useUsers({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: divisions } = useDivisions({
    options: { staleTime: 5 * 1000 * 60 },
  });
  const { data: pricebookItems } = usePricebookItems({
    options: { staleTime: 5 * 1000 * 60 },
  });

  const getPricebookById = (id: string) => {
    return pricebookItems?.data.priceBookItems?.find(
      (pricebookItem) => pricebookItem?.id === id
    );
  };

  const getUserNameById = (id: string) => {
    const matchedUser = users?.data.find((user) => user.id === id);
    if (matchedUser?.firstName && matchedUser.lastName) {
      return `${matchedUser.firstName} ${matchedUser.lastName}`;
    }
    return matchedUser?.email;
  };

  const getUserOptions = () => {
    return (
      users?.data
        .map((user) => {
          if (!user.firstName || !user.lastName) {
            return { id: user.id, value: user.email };
          }
          return {
            id: user.id,
            value: `${user.firstName} ${user.lastName}`,
          };
        })
        .sort((a, b) => a.value.localeCompare(b.value)) ?? []
    );
  };

  const getOccupationNameById = (id: string | undefined) => {
    if (!id) return;
    return accountData?.data.occupations.find(
      (occupation) => occupation.id === id
    )?.name;
  };
  const getOccupationOptions = () => {
    return (
      accountData?.data.occupations
        .map((occupation) => ({
          id: occupation.id,
          value: occupation.name,
        }))
        ?.sort((a, b) => a.value.localeCompare(b.value)) ?? []
    );
  };
  const getDivisionNameById = (id: string) => {
    return divisions?.data.find((division) => division.id === id)?.divisionName;
  };

  const getDivisionOptions = () => {
    return (
      (
        divisions?.data.map((division) => ({
          id: division.id,
          value: division.divisionName,
        })) as Option[]
      )?.sort((a, b) => a.value.localeCompare(b.value)) ?? [
        { id: "", value: "" },
      ]
    );
  };

  const getBranchNameById = (id: string) => {
    return branches?.data.find((branch) => branch.id === id)?.name;
  };
  const getBranchOptions = () => {
    return (
      (
        branches?.data.map((branch) => ({
          id: branch.id,
          value: branch.name,
        })) as Option[]
      )?.sort((a, b) => a.value.localeCompare(b.value)) ?? [
        { id: "", value: "" },
      ]
    );
  };

  const transformDisplayValue = (fieldId: string, value: any, name: string) => {
    // Date
    if (isDate(value)) {
      return format(value, "eeee, MM/dd/yyyy 'at' h:mmaaa");
    }

    // Boolean
    if (typeof value === "boolean") {
      return String(value);
    }

    // NullDash
    if (!value || value === "") {
      return "—";
    }

    // Photo Picker
    if (name === "Photo") {
      if (value?.length) {
        return value.map((val: { url: string }) => val.url).join(", \n");
      }
      return "Empty";
    }

    // Multi User Field
    if (Array.isArray(value) && value?.every((val) => !!val?.userId)) {
      return value
        .map((val) => {
          if (!val?.occupationId) {
            return getUserNameById(val.userId);
          }
          return `${getUserNameById(val.userId)} (${getOccupationNameById(
            val.occupationId
          )})`;
        })
        .join(", \n");
    }

    // Multi Quote Item Field
    if (Array.isArray(value) && value?.every((val) => !!val?.pricebookItemId)) {
      return value
        .map((val) => {
          return `${val?.name}-${Number(val.quantity).toFixed(0)}${
            val?.notes?.length > 0 ? `-${val.notes}` : ""
          }`;
        })
        .join(", \n");
    }

    // Is UUID
    if (z.string().uuid().safeParse(value).success) {
      const branchName = getBranchNameById(value);
      if (branchName) {
        return branchName;
      }
      const userName = getUserNameById(value);
      if (userName) {
        return userName;
      }
      const divisionName = getDivisionNameById(value);
      if (divisionName) {
        return divisionName;
      }
    }
    return getDisplayValue({ fieldId, value });
  };

  return {
    getOccupationNameById,
    transformDisplayValue,
    getBranchNameById,
    getUserOptions,
    getUserNameById,
    getOccupationOptions,
    getBranchOptions,
    getDivisionNameById,
    getDivisionOptions,
    getPricebookById,
  };
};
