import { classNames } from "@/utils/helpers/classNames";
import { FC, useCallback, useEffect, useRef } from "react";
import { JobFormSchema } from "@/utils/formDefinitions/jobManagementService/general";
import { Location } from "@/types/jobManagementService/general";
import { MapIcon } from "@/assets";
import { NORTH_AMERICA_BOUNDS } from "@/utils/helpers/map";
import { UseFormSetValue } from "react-hook-form";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

type Props = {
  onClickEndAdorment: () => void;
  setValue: UseFormSetValue<JobFormSchema>;
  initialLocation?: Location | null;
};

const CustomAutocomplete: FC<Props> = ({
  setValue,
  onClickEndAdorment,
  initialLocation,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  const EndAdornment = useCallback(() => {
    return (
      <button
        className="flex h-10 w-10 items-center justify-center rounded-r-md bg-palette-companyGreen"
        onClick={onClickEndAdorment}
        type="button"
      >
        <MapIcon pathClassName="stroke-white" />
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = initialLocation
        ? initialLocation?.name ?? initialLocation?.formattedAddress
        : "";
    }
  }, [initialLocation]);

  useEffect(() => {
    if (inputRef.current && places) {
      const googleAutocomplete = new places.Autocomplete(inputRef.current, {
        bounds: { ...NORTH_AMERICA_BOUNDS },
        strictBounds: true,
        fields: ["formatted_address", "geometry"],
      });

      googleAutocomplete.addListener("place_changed", () => {
        const place = googleAutocomplete.getPlace();

        if (place.geometry?.location) {
          setValue("location", {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            name: place.formatted_address || "",
          });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [places]);

  return (
    <div className={classNames("relative col-span-2 flex flex-col")}>
      <label
        className={classNames("text-sm font-medium text-[#575757]")}
        htmlFor="custom-google-autocomplete-1"
      >
        Location
      </label>

      <div className="relative">
        <input
          className="h-10 w-full rounded-md border border-[#949494] bg-[#fafdfe] px-3 text-[#141c22] placeholder:leading-6 placeholder:text-[#97989e] hover:border-[#393d41]"
          id="custom-google-autocomplete-1"
          placeholder="Select a Location"
          ref={inputRef}
        />

        <div
          className={classNames("absolute inset-y-0 right-0 flex items-center")}
        >
          <EndAdornment />
        </div>
      </div>
    </div>
  );
};

export { CustomAutocomplete };
