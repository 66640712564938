/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth0, LocalStorageCache } from "@auth0/auth0-react";
import * as React from "react";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { TypedDocumentString } from "@/lib/graphql/graphql";

interface IAuthWrapper {
  children?: ReactNode;
}

export type FetchWithAuthProps = {
  url: string;
  method?: string;
  body?: any;
  additionalHeaders?: { [key: string]: string };
};

type GraphqlRequest = <TResult, TVariables>(
  query: TypedDocumentString<TResult, TVariables>,
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
) => Promise<{ data: TResult }>;

interface IAuthContext {
  accountId: string;
  ribbiotId: string;
  divisionIds: string[];
  fetchWithAuth: (_params: FetchWithAuthProps) => Promise<any>;
  hasRibbiotAdmin: boolean | undefined;
  hasTimecardAdmin: boolean | undefined;
  hasWebAssetMap: boolean | undefined;
  hasWebSchedule: boolean | undefined;
  hasWorkflowCrud: boolean | undefined;
  hasAssetCrud: boolean | undefined;
  hasGroupCrud: boolean | undefined;
  hasUserCrud: boolean | undefined;
  hasWebQuoting: boolean | undefined;
  graphqlRequest: GraphqlRequest;
}

const AuthContext = createContext<IAuthContext>({
  accountId: "",
  ribbiotId: "",
  divisionIds: [],
  fetchWithAuth: async () => new Promise((res) => res),
  hasRibbiotAdmin: undefined,
  hasTimecardAdmin: undefined,
  hasWebAssetMap: undefined,
  hasWebSchedule: undefined,
  hasWorkflowCrud: undefined,
  hasAssetCrud: undefined,
  hasGroupCrud: undefined,
  hasUserCrud: undefined,
  hasWebQuoting: undefined,
  graphqlRequest: async () => new Promise((res) => res),
});

export const AuthWrapper: React.FC<IAuthWrapper> = ({ children }) => {
  const localStorageCache = new LocalStorageCache();
  const { isAuthenticated, getAccessTokenSilently, user, logout } = useAuth0();
  const [tokenExpiry, setTokenExpiry] = useState<number | undefined>();
  const [token, setToken] = useState<string | undefined>();
  const [hasTimecardAdmin, setHasTimecardAdmin] = useState<boolean>();
  const [hasRibbiotAdmin, setHasRibbiotAdmin] = useState<boolean>();
  const [hasWebAssetMap, setHasWebAssetMap] = useState<boolean>();
  const [hasWebSchedule, setHasWebSchedule] = useState<boolean>();
  const [hasWorkflowCrud, setHasWorkflowCrud] = useState<boolean>();
  const [hasAssetCrud, setHasAssetCrud] = useState<boolean>();
  const [hasGroupCrud, setHasGroupCrud] = useState<boolean>();
  const [hasUserCrud, setHasUserCrud] = useState<boolean>();
  const [hasWebQuoting, setHasWebQuoting] = useState<boolean>();
  const [accountId, setAccountId] = useState<string>("");
  const [ribbiotId, setRibbiotId] = useState<string>("");
  const [divisionIds, setDivisionIds] = useState<string[]>([]);
  const ldClient = useLDClient();

  const fetchWithAuth = async ({
    url,
    additionalHeaders,
    body,
    method,
  }: FetchWithAuthProps) => {
    let localFetchToken;
    const tokenTimeLeft =
      tokenExpiry &&
      Number(tokenExpiry - new Date().getTime() / 1000) * 1000 - 10000;
    if (!token || (!!tokenTimeLeft && tokenTimeLeft < 0)) {
      localFetchToken = await getAccessTokenSilently();
      setToken(localFetchToken);
      const parsedToken = JSON.parse(atob(localFetchToken.split(".")[1]));
      setTokenExpiry(parsedToken.exp);
    }

    return fetch(url, {
      method: method ?? "GET",
      headers: {
        Authorization: `Bearer ${localFetchToken ?? token}`,
        ...additionalHeaders,
      },
      ...(body && { body }),
    });
  };

  const graphqlRequest: GraphqlRequest = async (query, ...[variables]) => {
    const response = await fetchWithAuth({
      url: import.meta.env.VITE_GRAPHQL_URL,
      method: "POST",
      additionalHeaders: {
        "Content-Type": "application/json",
        Accept: "application/graphql-response+json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to fetch: ${
          response.statusText
        }. Body: ${await response.text()}`
      );
    }
    return response.json();
  };

  const initializeAuth = async () => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      // Catches if consent has changed or previously logged in account has changed, therefore must logout
      console.error(error);
    }

    const logged = await localStorageCache.get("login");
    if (!token) {
      localStorageCache.remove("login");
      localStorage.clear();
      logout({
        logoutParams: { returnTo: window.location.origin },
      });
      return;
    }
    const parsedToken = JSON.parse(atob(token.split(".")[1]));
    const permissions = parsedToken.permissions as string[];
    setHasTimecardAdmin(permissions.includes("timecard:admin"));
    setHasRibbiotAdmin(permissions.includes("ribbiot:admin"));
    setHasWebAssetMap(permissions.includes("web:assetmap"));
    setHasWebSchedule(permissions.includes("web:schedule"));
    setHasWorkflowCrud(permissions.includes("web:workflowcrud"));
    setHasAssetCrud(permissions.includes("web:assetcrud"));
    setHasGroupCrud(permissions.includes("web:groupcrud"));
    setHasUserCrud(permissions.includes("web:usercrud"));
    setHasWebQuoting(permissions.includes("web:quoting"));
    setAccountId(parsedToken.accountId);
    setRibbiotId(parsedToken.ribbiotId);
    setDivisionIds(parsedToken.divisionIds);
    setToken(token);
    setTokenExpiry(parsedToken.exp);

    if (user?.email?.includes("playwright")) return;
    const getAmplitudeId = () => {
      if (
        !parsedToken?.ribbiotId ||
        parsedToken?.ribbiotId === "..." ||
        parsedToken?.ribbiotId === ""
      ) {
        return user?.sub;
      }
      return parsedToken.ribbiotId;
    };

    amplitude.setUserId(getAmplitudeId());
    amplitude.setGroup("account_id", parsedToken.accountId);
    const viewportIdentify = new amplitude.Identify()
      .set("viewport", `${innerWidth}px x ${innerHeight}px`)
      .set("screen_resolution", `${screen.width}px x ${screen.height}px`);
    amplitude.identify(viewportIdentify);

    if (!logged) {
      amplitude.track(AMPLITUDE_EVENTS.LOGIN);
      localStorageCache.set("login", new Date());
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      initializeAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    const context = ldClient?.getContext();
    if (!context?.key && context?.key !== ribbiotId) {
      ldClient?.identify({ key: ribbiotId });
    }
  }, [ldClient, ribbiotId]);

  return (
    <AuthContext.Provider
      value={{
        accountId,
        ribbiotId,
        divisionIds,
        fetchWithAuth,
        hasRibbiotAdmin,
        hasTimecardAdmin,
        hasWebAssetMap,
        hasWebSchedule,
        hasWorkflowCrud,
        hasAssetCrud,
        hasGroupCrud,
        hasUserCrud,
        hasWebQuoting,
        graphqlRequest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
