/* eslint-disable @typescript-eslint/no-explicit-any */
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { Flyout } from "@/UI/Flyout";
import { RangePicker } from "@/UI/RangePicker";
import { Table } from "../Table";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useAuthContext } from "../Auth/AuthWrapper";
import { useState, useEffect, FC } from "react";
import {
  formatDateOnlyTimezone,
  formatDateToDateOnly,
} from "@/utils/helpers/dateFormat";
import { useInfiniteFormSubmissions } from "@/lib/react-query/queryHooks/useInfiniteFormSubmissions";
import { sub } from "date-fns";
import { ColumnFiltersState } from "@tanstack/react-table";
import { DynamicPageField, Form, FormSubmission } from "@/types/forms/general";
import { getQuotingColumnDefinitions } from "@/utils/columnDefinitions/quotingColumn";
import { QuotingFilters } from "./QuotingFIlters";
import { useDocumentsHelpers } from "@/utils/hooks/useDocumentsHelpers";
import {
  EditFormSubmissionBody,
  useEditFormSubmission,
} from "@/lib/react-query/mutationHooks/useEditFormSubmission";
import { toast } from "react-toastify";
import { Button } from "@/UI/Button";
import { useNavigate } from "react-router-dom";

export const Quotes: FC = () => {
  const { accountId } = useAuthContext();
  const navigate = useNavigate();
  const [formColumnFiltersState, setFormColumnFiltersState] =
    useState<ColumnFiltersState>([]);
  const [quoteForm, setQuoteForm] = useState<Form>();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    sub(new Date(), { days: 7 }),
    new Date(),
  ]);
  const [filterStartDate, filterEndDate] = [
    formatDateToDateOnly(dateRange[0]),
    formatDateToDateOnly(dateRange[1]),
  ];
  const [filters, setFilters] = useState<DynamicPageField[]>();

  const {
    isLoading: accountLoading,
    isSuccess: accountSuccess,
    isFetching: accountFetching,
    data: account,
  } = useAccount({
    accountId,
    options: { staleTime: 5 * 1000 * 60 },
  });

  const [quotingData, setQuotingData] = useState<FormSubmission[]>();

  const formsById = useInfiniteFormSubmissions({
    formId: quoteForm?.id,
    endDate: filterEndDate,
    startDate: filterStartDate,
  });

  const onDateRange = (values: [Date | null, Date | null]) => {
    setDateRange([values[0], values[1]]);
  };

  const getTableData = () => {
    if (accountLoading || formsById.isFetching) return [];
    return quotingData;
  };

  const { handleEditFormSubmissionSuccess } = useDocumentsHelpers();

  const {
    mutateAsync: editFormSubmission,
    isPending: editFormSubmissionLoading,
    variables: editFormSubmissionVariables,
  } = useEditFormSubmission();

  const handleEditFormSubmission = ({
    submissionId,
    body,
  }: {
    submissionId: string;
    body: EditFormSubmissionBody;
  }) => {
    if (!quoteForm?.id) return;
    editFormSubmission(
      { formId: quoteForm.id, submissionId, body },
      {
        onSuccess: (successData) => {
          toast.success("Document updated.");
          handleEditFormSubmissionSuccess({
            body,
            filterEndDate,
            filterStartDate,
            selectedDocument: { id: quoteForm.id, value: quoteForm.name },
            submissionId,
            successData,
          });
        },
        onError: () => {
          toast.error("Unable to update document.");
        },
      }
    );
  };

  const getTableColumns = () => {
    return getQuotingColumnDefinitions({
      handleEditFormSubmission,
      editFormSubmissionContext: {
        submissionId: editFormSubmissionVariables?.submissionId,
        body: editFormSubmissionVariables?.body,
        loading: editFormSubmissionLoading,
      },
    });
  };

  const getTableLoading = () => {
    if (accountFetching || formsById.isFetching) return true;
    return false;
  };

  const getTableFilters = () => {
    return formColumnFiltersState;
  };

  const getFieldIdByName = (name: string) => {
    const flatQuoteFormFields = quoteForm?.fields.flatMap((field) => {
      if (field?.fields) {
        return field.fields;
      }
      return field;
    });
    return flatQuoteFormFields?.find((field) =>
      field.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
    )?.id;
  };

  const configureFilters = (quoteForm: Form | undefined) => {
    if (!quoteForm) return;
    // This is temporary so that I don't have to create a separate JSON file configuration just for quoting YET
    setFilters([
      {
        id: getFieldIdByName("customer"),
        name: "Customer Name",
        type: "TextField",
        source: "fields",
      },
      {
        id: getFieldIdByName("job name"),
        name: "Job Name",
        type: "TextField",
        source: "fields",
      },
      {
        name: "Status",
        path: "data.status",
        source: "metadata",
        type: "StatusSelector",
      },
    ]);
  };

  useEffect(() => {
    if (!accountSuccess) return;
    const quoteFormInAccount = account.data.forms.find((form) =>
      // TODO: Don't do this. This is so hacky
      form.name.toLocaleLowerCase().includes("quote")
    );
    configureFilters(quoteFormInAccount);
    setQuoteForm(quoteFormInAccount);
  }, [accountSuccess]);

  useEffect(() => {
    if (!formsById?.data || formsById?.data?.pages?.length < 1) return;
    const formSubmissionData = formsById.data.pages.flatMap(
      (page) => page.data
    );
    const data = formSubmissionData.map((formSubmission: any) => {
      formSubmission["Job Name"] = formSubmission.fields.find(
        (field: any) => field.id === getFieldIdByName("job name")
      )?.value;
      formSubmission["Customer Name"] = formSubmission.fields.find(
        (field: any) => field.id === getFieldIdByName("customer")
      )?.value;
      formSubmission["Date Created"] = formatDateOnlyTimezone(
        formSubmission.submitDateTime,
        formSubmission.timezoneOffset
      );
      formSubmission["Created By"] = formSubmission.userName;
      formSubmission["Status"] = formSubmission?.data?.status ?? "Draft";
      return formSubmission;
    });
    setQuotingData(data);
  }, [formsById.data]);

  const handleQuoteDetailNavigation = (formSubmissionId: string) => {
    navigate(
      `quote-detail?formId=${quoteForm?.id}&formSubmissionId=${formSubmissionId}`
    );
  };

  return (
    <>
      <header className="mb-5 flex w-full flex-row justify-between">
        <div className="flex items-center gap-4">
          <h1 className="text-4xl font-semibold">Quotes</h1>
        </div>
        <div className="flex items-center gap-4">
          <RangePicker
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onChange={onDateRange}
            onDateRange={onDateRange}
            className="mr-4 w-[230px]"
          />
          <Flyout
            popoverButton={
              <div className="flex items-center rounded-3xl bg-theme-green-primary px-7 py-2 text-white">
                <AdjustmentsHorizontalIcon
                  className="mx-1"
                  accentHeight={20}
                  height={20}
                />
                Filters
                <span className="ml-1">
                  {formColumnFiltersState.length > 0
                    ? `(${formColumnFiltersState.length.toString()})`
                    : ""}
                </span>
              </div>
            }
            content={
              <QuotingFilters
                filters={filters ?? []}
                quotingData={quotingData}
                formColumnFiltersState={formColumnFiltersState}
                setFormColumnFiltersState={setFormColumnFiltersState}
              />
            }
          />
          <Button
            label="New Quote"
            variant="primary"
            className="rounded-full text-white"
            onClick={() => navigate(`/quoting/create?formId=${quoteForm?.id}`)}
            disabled={!quoteForm?.id}
          />
        </div>
      </header>
      <div className="h-[75vh] w-full overflow-auto rounded-md bg-white">
        <Table
          emptyMessage="No quotes exist for the selected date range and filters."
          filters={getTableFilters()}
          loading={getTableLoading()}
          tableColumns={getTableColumns()}
          tableData={getTableData() ?? []}
          onRowClick={(row) => {
            handleQuoteDetailNavigation(row.original.id);
          }}
          clickableRow
          thClasses="!uppercase !tracking-[.52] !font-medium !text-[13px] !text-palette-black"
          theadClasses="!bg-[#fafafa]"
          trClasses="hover:bg-[#e9f2f4]"
        />
      </div>
    </>
  );
};
