import { classNames } from "@/utils/helpers/classNames";
import { FC, ReactNode } from "react";
import { Sidebar } from "../components/Sidebar";
import { useLocation } from "react-router-dom";
import { Fallback } from "@/UI/Fallback";

type Props = {
  children: ReactNode;
};

const DefaultLayout: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const hideSidebar = pathname === "/";
  const map = pathname === "/map";

  if (pathname === "/error") {
    return <Fallback signOut={false} />;
  }

  return (
    <main className="flex h-[100vh] w-[100vw] bg-palette-companyBackground">
      {!hideSidebar && <Sidebar />}

      <div
        id="default-layout"
        className={classNames(
          "box-border flex h-[100%] w-[100%] min-w-[1140px] flex-col items-center overflow-auto",
          map ? "" : "px-16 py-4"
        )}
      >
        {children}
      </div>
    </main>
  );
};

export { DefaultLayout };
